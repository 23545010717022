import styled from '@emotion/styled';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SessionRenderType, SessionState } from 'wavepaths-shared/core';

import { Button } from '@/component-library';
import { useRemoteElapsedTimeSecs, useRemoteSessionState } from '@/hooks';

import { AudioPlayerContext } from '../../sessions/EndedSessionsTable/useAudioPlayer';
import { PlayerChoiceContext } from '../deviceOptions/PlayerChoiceContext';
import { RemoteSessionControlsContext } from '../Guide';

interface IStartEndSessionButtonProps {
    onStartSession: () => void;
    onEndSession: () => void;
    broadcastIdentifier: string;
}

export const START_LABEL = 'Start Session';
export const StartButton = ({ onStartSession }: { onStartSession: () => void }) => {
    const [clicked, setClicked] = useState(false);

    const handleStartSession = () => {
        setClicked(true);
        onStartSession();
    };

    return (
        <Button
            className="tour-startSession"
            size="l"
            variant="solid-blue"
            disabled={clicked}
            onClick={handleStartSession}
            style={{ backgroundColor: '#2B2D3F', color: '#ffffff', fontWeight: 600 }}
        >
            {START_LABEL}
        </Button>
    );
};

export const END_LABEL = 'End Session';
const EndButton = ({ onEndSession }: { onEndSession: () => void }) => {
    const [clicked, setClicked] = useState(false);

    const handleEndSession = () => {
        setClicked(true);
        onEndSession();
    };

    return (
        <Button
            size="l"
            variant="solid-blue"
            disabled={clicked}
            onClick={handleEndSession}
            style={{ backgroundColor: '#2B2D3F', color: '#ffffff', fontWeight: 600 }}
        >
            {END_LABEL}
        </Button>
    );
};

const Container = styled.div`
    display: inline-grid;
    place-content: center;
    gap: 10px;
`;

const StartContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export default function StartEndSessionButton({
    onStartSession,
    onEndSession,
}: //broadcastIdentifier,
IStartEndSessionButtonProps): JSX.Element {
    const remoteSession = useContext(RemoteSessionControlsContext);
    if (!remoteSession) {
        throw new Error('no RemoteSessionControlsContext');
    }
    const isPrelude =
        remoteSession.tick && [SessionState.PRELUDE, SessionState.PLANNED].includes(remoteSession.tick.sessionState);
    const isPostlude =
        remoteSession.tick && [SessionState.POSTLUDE, SessionState.ENDED].includes(remoteSession.tick.sessionState);
    const isEnded = remoteSession.tick && remoteSession.tick.sessionState === SessionState.ENDED;
    const { paused } = useRemoteSessionState();

    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    const audioPlayer = useContext(AudioPlayerContext);
    const audioLoaded = useRef(false);

    const elapsedTimeSecs = useRemoteElapsedTimeSecs();

    useEffect(() => {
        (async () => {
            if (!audioLoaded.current) return;

            if (paused) {
                audioPlayer?.actions.pause({ reason: 'user' });
            } else {
                await audioPlayer?.actions.unblock();
                audioPlayer?.actions.play({ offsetSeconds: elapsedTimeSecs });
            }
        })();
    }, [paused]);

    useEffect(() => {
        (async () => {
            console.debug('Init playback', {
                sessionInitialised: remoteSession.sessionInitialised,
                whereToPlayMusic: playerChoiceContext.whereToPlayMusic,
                playerChoice: playerChoiceContext.playerChoice,
                sessionStarted: remoteSession.sessionStarted,
                audioLoaded: audioLoaded.current,
                isPostlude,
                paused,
            });
            if (
                playerChoiceContext.whereToPlayMusic !== 'remoteOnly' &&
                playerChoiceContext.playerChoice === 'browser'
            ) {
                if (!isPostlude) {
                    if (remoteSession.sessionInitialised && !audioLoaded.current) {
                        (async () => {
                            if (!remoteSession.sessionStarted) {
                                console.debug('Playing prelude...');
                                await audioPlayer?.actions.unblock();
                                if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                                    //For real-time prelude is part of the main record
                                    audioPlayer?.actions.play();
                                } else {
                                    audioPlayer?.actions.playPreludePostlude();
                                }
                            } else {
                                if (!paused) {
                                    await audioPlayer?.actions.unblock();
                                    audioPlayer?.actions.play();
                                }
                            }
                            audioLoaded.current = true;
                        })();
                    }
                    if (audioLoaded.current && remoteSession.sessionStarted) {
                        if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                            //noop as its already part of the main score
                        } else {
                            if (!paused) {
                                console.debug('Playing main phase...');
                                if (audioPlayer === undefined) throw new Error('No audioPlayer');
                                await audioPlayer?.actions.unblock();
                                audioPlayer?.actions.play();
                            }
                        }
                    }
                } else {
                    if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                        //noop as the postlude is part of the main score
                    } else {
                        console.debug('Playing postlude...');
                        audioPlayer?.actions.pause({ fadeMs: 30 * 1000, reason: 'sessionEnd' });
                        audioPlayer?.actions.playPreludePostlude();
                    }
                }
            }
        })();
    }, [
        remoteSession.sessionInitialised,
        playerChoiceContext.whereToPlayMusic,
        remoteSession.sessionStarted,
        audioLoaded.current,
        isPostlude,
        playerChoiceContext.playerChoice,
    ]);

    const showContainer =
        isPostlude ||
        isPrelude ||
        !playerChoiceContext.whereToPlayMusic ||
        (playerChoiceContext.whereToPlayMusic !== 'remoteOnly' && playerChoiceContext.playerChoice !== 'browser');
    //&& !dismissedPlayerChoice

    return (
        <>
            {showContainer && (
                <Container>
                    {isPostlude && !isEnded && <EndButton onEndSession={onEndSession} />}

                    {(isPrelude || !playerChoiceContext.whereToPlayMusic) && (
                        <StartContainer>
                            {playerChoiceContext.whereToPlayMusic !== undefined && isPrelude ? (
                                <StartButton onStartSession={onStartSession} />
                            ) : (
                                <></>
                            )}
                        </StartContainer>
                    )}
                </Container>
            )}
        </>
    );
}
