import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { useSessionStorage } from 'react-use';
import { BroadcastPersistentState, SessionRenderType } from 'wavepaths-shared/core';

import { GlobalSnackbarContext } from '@/components/Snackbar';
import { Features } from '@/features';
import { useRemoteElapsedTimeSecs } from '@/hooks';

import { useAuthContext } from '../../../auth';
import { AudioPlayerContext, useAudioPlayer } from '../../sessions/EndedSessionsTable/useAudioPlayer';
import { RemoteSessionControlsContext } from '../Guide';

const AudioPlayerHookWrapper = ({
    outputDevice,
    children,
}: {
    outputDevice: string | undefined;
    children: ReactNode;
}) => {
    const broadcastElapsedTimeSecs = useRemoteElapsedTimeSecs();
    const { isEnabled } = useAuthContext();

    const remoteSession = useContext(RemoteSessionControlsContext);
    if (remoteSession === undefined) throw new Error('No RemoteSessionControlsContext');
    if (remoteSession.session === undefined) throw new Error('No remoteSession.session');

    const session = remoteSession.session;

    const playDemoVO = !isEnabled(Features.FREE_ACCESS);
    //TODO initial state from persistent storage
    const [broadcastState, setBroadcastState] = useState<BroadcastPersistentState>(
        session.broadcastState || {
            timeline: [{ sessionId: session.id, dspOffset: 0 }],
            discarded: [],
        },
    );

    useEffect(() => {
        if (!remoteSession?.connection) return;

        remoteSession.connection.on('broadcastStateUpdate', (data: BroadcastPersistentState) => {
            setBroadcastState(data);
            console.debug('New broadcastState', data);
        });
    }, [remoteSession?.connection]);

    const audioPlayer = useAudioPlayer({
        outputDevice,
        broadcastIdentifier: session.broadcastIdentifier,
        errorContext: 'Adaptive Session',
        mode: session.renderType == SessionRenderType.PREDICTIVE_COMPOSED ? 'recording' : 'live',
        broadcastState,
        broadcastElapsedTimeSecs,
        voiceOverStages:
            session.renderType === SessionRenderType.PREDICTIVE_COMPOSED ? session.score.voiceover ?? [] : [],
        playDemoVO,
    });

    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    useEffect(() => {
        setSnackbarContent(audioPlayer.warning);
    }, [audioPlayer.warning, setSnackbarContent]);

    return <AudioPlayerContext.Provider value={audioPlayer}>{children}</AudioPlayerContext.Provider>;
};

const AudioPlayerContextWrapper = ({ children }: { children: ReactNode }) => {
    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('No PlayerChoiceContext');
    const playHere =
        playerChoiceContext.playerChoice === 'browser' &&
        (playerChoiceContext.whereToPlayMusic === 'thisDevice' ||
            playerChoiceContext.whereToPlayMusic === 'thisAndRemote');
    return (
        <>
            {playHere ? (
                <AudioPlayerHookWrapper outputDevice={playerChoiceContext.browserPlayerOutputDevice}>
                    {children}
                </AudioPlayerHookWrapper>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

export type DialogState = 'openInitial' | 'reopen' | 'closed';
export type WhereToControl = 'controlOnThisDevice' | 'controlOnRemoteDevice';
export type WhereToPlayMusic = 'thisDevice' | 'remoteOnly' | 'thisAndRemote';
export type PlayerChoice = 'browser' | 'native';

type PlayerChoiceState = {
    dialogState: DialogState;
    whereToControl: WhereToControl;
    whereToPlayMusic: WhereToPlayMusic | undefined;
    playerChoice: PlayerChoice;
    browserPlayerOutputDevice: string | undefined;
};
export const usePlayerChoice = (renderingType: SessionRenderType) => {
    const [playerChoiceState, setPlayerChoiceState] = useSessionStorage<PlayerChoiceState>('player-choice', {
        dialogState: 'openInitial',
        whereToControl: 'controlOnThisDevice',
        whereToPlayMusic: 'thisDevice',
        playerChoice: isSafari && renderingType !== SessionRenderType.REAL_TIME ? 'native' : 'browser',
        browserPlayerOutputDevice: undefined,
    });
    useEffect(() => {
        // Always open initially on page load, so we always have an explicit click action to start audio.
        setPlayerChoiceState({
            ...playerChoiceState,
            dialogState: 'openInitial',
        });
    }, []);
    const {
        dialogState,
        whereToControl,
        whereToPlayMusic,
        playerChoice,
        browserPlayerOutputDevice,
    } = playerChoiceState;
    return {
        isDialogOpen: dialogState === 'openInitial' || dialogState === 'reopen',
        isInitialDialog: dialogState === 'openInitial',
        openDialog: () => setPlayerChoiceState({ ...playerChoiceState, dialogState: 'reopen' }),
        closeDialog: () => setPlayerChoiceState({ ...playerChoiceState, dialogState: 'closed' }),
        update: (update: Partial<PlayerChoiceState>) => setPlayerChoiceState({ ...playerChoiceState, ...update }),
        closeDialogWithUpdate: (update: Partial<PlayerChoiceState>) =>
            setPlayerChoiceState({ ...playerChoiceState, dialogState: 'closed', ...update }),
        whereToControl,
        whereToPlayMusic,
        playerChoice,
        browserPlayerOutputDevice,
    };
};

export const PlayerChoiceContextWrapper = ({
    sessionRenderType,
    children,
}: {
    sessionRenderType: SessionRenderType;
    children: React.ReactNode;
}) => {
    const playerChoiceContextValue = usePlayerChoice(sessionRenderType);
    return (
        <PlayerChoiceContext.Provider value={playerChoiceContextValue}>
            <AudioPlayerContextWrapper>{children}</AudioPlayerContextWrapper>
        </PlayerChoiceContext.Provider>
    );
};

export const PlayerChoiceContext = createContext<ReturnType<typeof usePlayerChoice> | undefined>(undefined);
